<template>
    <div>
        <AktuellComp></AktuellComp>
        <Archiv></Archiv>
    </div>
</template>

<script>
    import AktuellComp from '../components/Aktuell.vue';
    import Archiv from '../components/Dokumente/Archiv.vue';

    export default {
        name: "Aktuelles",
        components: {
            AktuellComp,
            Archiv
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>